<!--
 * @Description: 全局页面配置  
				titleShow: true, //是否全屏幕 不显示顶部导航条
				title: '', //标题
				desc: '', //描述
				bgImg: '', //背景图
				checkUserStatus: false, //是否用户必须登录才可以访问
-->
<template>
	<div class="p10">
		<label class=" f12">此配置适用于当前页面----- {{curPage.name}}</label>
		<config-item label='背景色'>
			<config-color-picker v-model="curPage.config.backgroundColor"></config-color-picker>
		</config-item>
		<config-item label='标题'>
			<el-input v-model="curPage.config.pageTitle" placeholder="请输入标题" size="small" @change="handleChange"></el-input>
		</config-item>
		<config-item label='描述'>
			<el-input v-model="curPage.config.pageDesc" placeholder="请输入描述" size="small"></el-input>
		</config-item>
		<config-item label='分享图片'>
			<FaImageUpload v-model="curPage.config.shareImg" :maxCount="1" />
		</config-item>
		<config-item label='背景图片'>
			<FaImageUpload v-model="curPage.config.bgImg" :maxCount="1" @change="handleChange" />
		</config-item>
		<config-item label='强制登录'>
			<el-switch v-model="curPage.config.pageLogin" class="mt8" @change="handleChange">
			</el-switch>
		</config-item>
		<config-item label='顶部导航'>
			<el-switch v-model="curPage.config.showTopTitle" class="mt8">
			</el-switch>
		</config-item>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "PageConfig",
		data() {
			return {
				// page: [],
			}
		},
		computed: {
			...mapGetters(["project"]),
			...mapGetters(["curPage"]), //当前页面数据
		},
		mounted() {
			console.log("页面背景图等设计curPage:", this.curPage);
			console.log("页面背景图等设计project.pages:", this.project.pages);
			let id = this.curPage.id;
			//将find找到的数据全部传值给this.page
			this.page = this.project.pages.find((page) => (page.id == id));
			console.log("页面背景图等设计this.page:", this.page);
			//需要找到后存入到project对应的page下
		},
		methods: {
			//改变图片后立刻同步到
			handleChange() {
				console.log('The message is changed.');
			}
		},



	};
</script>